export const gameTitles = [
  'apex',
  'valorant',
  'myHeroAcademiaUltraRumble',
  'valorantMobile',
  'escapeFromTarcov',
  'genshin',
  'overwatch2',
  'fortnite',
  'dbd',
  'dbdMobile',
  'counterStrikeGlobalOffensive',
  'rust',
  'gta5',
  'pso2',
  'ff14',
  'ff16',
  'monsterHunterNow',
  'monsterhunterrise',
  'monsterHunterRiseSunBreak',
  'monsterHunterWorld',
  'monsterHunterWorldIceBorne',
  'monsterHunterXx',
  'shatterline',
  'wildHearts',
  'phasmophobia',
  'yugiohMasterDuel',
  'amongus',
  'eldenRing',
  'ghostOfTsushima',
  'back4Blood',
  'humanFallFlat',
  'superSmashBrosUltimate',
  'animalCrossingNewHorizons',
  'pokemonSv',
  'pokemonArceus',
  'pokemonUnite',
  'pokemonBrilliantDiamondShiningPearl',
  'pokemonGo',
  'identityV',
  'codMobile',
  'codVanguard',
  'codBo3',
  'codBo4',
  'codBoColdWar',
  'codBo6',
  'codMw2',
  'codMw3',
  'codWarzone',
  'mineCraft',
  'mineCraftDungeons',
  'splatoon2',
  'splatoon3',
  'lol',
  'lolWildRift',
  'gundamVersus',
  'gundamBattleOperation2',
  'gundamEvolution',
  'gundamExVsMbOn',
  'blazBlueCrossTagBattle',
  'dragonBallFighterZ',
  'dragonBallTheBreakers',
  'ark',
  'f12021',
  'streetFighterV',
  'streetFighter6',
  'granBlueFantasy',
  'granBlueFantasyVersus',
  'guiltyGearStrive',
  'meltyBloodTypeLumina',
  'othellonia',
  'pokkenTournamentDx',
  'sekainoAsobi51',
  'marioKart8Dx',
  'diablo3',
  'diablo4',
  'bloodHunt',
  'sky',
  'brawlStars',
  'tekken7',
  'knivesOut',
  'pubg',
  'pubgMobile',
  'pubgNewState',
  'fallguys',
  'r6s',
  'rainbowSixExtraction',
  'destiny2',
  'proSeka',
  'mahjongSoul',
  'mahjongKings',
  'pazudora',
  'shadowverse',
  'shironekoTennis',
  'monsterStrike',
  'rocketLeague',
  'momotetsuTeiban',
  'bf5',
  'bf2042',
  'edf5',
  'edf6',
  'compass',
  'vrChat',
  'roblox',
  'needForSpeed',
  'granTurismoSport',
  'granTurismo7',
  'puyopuyoTetris2',
  'lifeAfter',
  'overcooked',
  'overcooked2',
  'mobileLegends',
  'warframe',
  'bangDream',
  'duelMastersPlays',
  'worldOfWarshipsLegends',
  'worldOfWarshipsTanks',
  'factorio',
  'darkAndDarker',
  'dragonQuest10',
  'houkai3rd',
  'houkaiStarRail',
  'unrailed',
  'theDivision2Pc',
  'theDivision2PlayStation',
  'nanatsunotaizaiGrandCross',
  'westHunt',
  'towerOfFantasy',
  'titanFall2',
  'woLongFallenDynasty',
  'codeVein',
  'fifa2023',
  'eFootball2023',
  'gears5',
  'haloInfinite',
  'farlight84',
  'omegaStrikers',
  'xdefiant',
  'earthRevival',
  'ashTale',
  'ultimateChickenHorse',
  'gangBeasts',
  'noMansSky',
  'sonsOfTheForest',
  'sevenDaysToDie',
  'theOutlastTrials',
  'jumpForce',
  'godField',
  'secretNeighbor',
  'marvelsAvengers',
  'meetYourMaker',
  'arcaea',
  'blueProtocol',
  'onePieceThousandStorm',
  'nioh2',
  'residentEvil5',
  'ghostWatchers',
  'itTakesTwo',
  'pacify',
  'raft',
  'aceCombat7',
  'garticPhone',
  'dontStarveTogether',
  'battleBitRemastered',
  'blackDesertPc',
  'blackDesertConsole',
  'blackDesertMobile',
  'logresJapaneseRpg',
  'theHunterCallOfTheWild',
  'exoprimal',
  'redDeadRedemption2',
  'rogueCompany',
  'theLegendOfZeldaTearsOfTheKingdom',
  'pikmin4',
  'craftopia',
  'daemonXMachina',
  'narakaBladepoint',
  'godEater3',
  'remnant2',
  'plateUp',
  'clashRoyale',
  'clashOfClans',
  'coreKeeper',
  'paladins',
  'dyingLight',
  'hogwartsLegacy',
  'projectF',
  'onePieceBountyRush',
  'farCry6',
  'arenaBreakout',
  'ghostReconWildlands',
  't3Arena',
  'starSiegeDeadZone',
  'worldDaiStar',
  'superMarioMaker2',
  'smashDunk',
  'castleAndDragon',
  'odinValhallaRising',
  'nyankoDaisensou',
  'professionalBaseballSpiritsA',
  'saoIntegralFactor',
  'lostLight',
  'albionOnline',
  'fallout76',
  'dissidiaFinalFantasyNt',
  'armoredCore6',
  'satisfactory',
  'goddessOfVictoryNikke',
  'sengokuDynasty',
  'predeterHuntingGrounds',
  'theTexasChainSawMassacre',
  'wayfinder',
  'terraria',
  'valheim',
  'stardewValley',
  'payDay2',
  'payDay3',
  'synced',
  'darkSouls3',
  'umaMusumePrettyDerby',
  'yokaiPunipuni',
  'superBombermanR2',
  'joJosBizarreAdventureAllStarBattleR',
  'partyAnimals',
  'warThunder',
  'blueArchive',
  'fridayThe13thTheGame',
  'powerfulProBaseballGloryNineCrossroads',
  'fateGrandOrder',
  'eaSportsFc24',
  'farmingSimulator22',
] as const

export type GameTitle = (typeof gameTitles)[number]

export const profileCardGameTitles = [
  'apex',
  'genshin',
  'valorant',
  'splatoon3',
  'overwatch2',
  'myHeroAcademiaUltraRumble',
  'codMw2',
  'codMw3',
  'codBo6',
  'blueProtocol',

  'pazudora',
  'bf2042',
  'pokemonUnite',
  'fortnite',
  'dbd',
  'monsterhunterrise',
  'monsterHunterRiseSunBreak',
  'amongus',
  'fallguys',
  'gundamEvolution',
] as GameTitle[]

export const getGameTitleDisplayName = (gameTitle: string) => {
  switch (gameTitle) {
    case 'joining':
      return '参加中'
    case 'myHeroAcademiaUltraRumble':
      return '僕のヒーローアカデミア Ultra Rumble'
    case 'apex':
      return 'Apex Legends'
    case 'valorant':
      return 'VALORANT'
    case 'valorantMobile':
      return 'VALORANTモバイル'
    case 'escapeFromTarcov':
      return 'タルコフ'
    case 'genshin':
      return '原神'
    case 'overwatch2':
      return 'オーバーウォッチ2'
    case 'fortnite':
      return 'フォートナイト'
    case 'dbd':
      return 'DbD'
    case 'dbdMobile':
      return 'DbDモバイル'
    case 'counterStrikeGlobalOffensive':
      return 'CS:GO'
    case 'rust':
      return 'Rust'
    case 'gta5':
      return 'GTA5'
    case 'pso2':
      return 'PSO2'
    case 'ff14':
      return 'FF14'
    case 'ff16':
      return 'FF16'
    case 'monsterHunterNow':
      return 'モンハンNOW'
    case 'monsterhunterrise':
      return 'モンハンライズ'
    case 'monsterHunterRiseSunBreak':
      return 'モンハンライズ:サンブレイク'
    case 'monsterHunterWorld':
      return 'モンハンワールド'
    case 'monsterHunterWorldIceBorne':
      return 'モンハン:アイスボーン'
    case 'monsterHunterXx':
      return 'モンハン:ダブルクロス'
    case 'shatterline':
      return 'Shatterline'
    case 'wildHearts':
      return 'WILD HEARTS'
    case 'phasmophobia':
      return 'Phasmophobia'
    case 'yugiohMasterDuel':
      return '遊戯王マスターデュエル'
    case 'amongus':
      return 'Among Us'
    case 'eldenRing':
      return 'エルデンリング'
    case 'ghostOfTsushima':
      return 'Ghost of Tsushima'
    case 'back4Blood':
      return 'B4B'
    case 'humanFallFlat':
      return 'Human Fall Flat'
    case 'superSmashBrosUltimate':
      return 'スマブラSP'
    case 'animalCrossingNewHorizons':
      return 'あつ森'
    case 'pokemonSv':
      return 'ポケモンスカーレット・バイオレット'
    case 'pokemonArceus':
      return 'ポケモンアルセウス'
    case 'pokemonUnite':
      return 'ポケモンユナイト'
    case 'pokemonBrilliantDiamondShiningPearl':
      return 'ダイパリメイク'
    case 'pokemonGo':
      return 'ポケモンGO'
    case 'identityV':
      return '第五人格'
    case 'codMobile':
      return 'CoD:Mobile'
    case 'codVanguard':
      return 'CoD:Vanguard'
    case 'codBo3':
      return 'CoD:BO3'
    case 'codBo4':
      return 'CoD:BO4'
    case 'codBoColdWar':
      return 'CoD:Black Ops Cold War'
    case 'codBo6':
      return 'CoD:BO6'
    case 'codMw2':
      return 'CoD:MW2'
    case 'codMw3':
      return 'CoD:MW3'
    case 'codWarzone':
      return 'CoD:Warzone'
    case 'mineCraft':
      return 'マイクラ'
    case 'mineCraftDungeons':
      return 'マイクラダンジョンズ'
    case 'splatoon2':
      return 'スプラ2'
    case 'splatoon3':
      return 'スプラ3'
    case 'lol':
      return 'LoL'
    case 'lolWildRift':
      return 'LoL:ワイルドリフト'
    case 'gundamVersus':
      return 'ガンダムバーサス'
    case 'gundamBattleOperation2':
      return 'ガンダムバトルオペレーション2'
    case 'gundamEvolution':
      return 'ガンダムエボリューション'
    case 'gundamExVsMbOn':
      return '機動戦士ガンダム EXTREME VS. マキシブーストON'
    case 'blazBlueCrossTagBattle':
      return 'ブレイブルークロスタッグバトル'
    case 'dragonBallFighterZ':
      return 'ドラゴンボールファイターズ'
    case 'dragonBallTheBreakers':
      return 'ドラゴンボールザブレイカーズ'
    case 'ark':
      return 'ARK'
    case 'f12021':
      return 'F12021'
    case 'streetFighterV':
      return 'ストリートファイターV'
    case 'streetFighter6':
      return 'ストリートファイター6'
    case 'granBlueFantasy':
      return 'グラブル'
    case 'granBlueFantasyVersus':
      return 'グラブルバーサス'
    case 'guiltyGearStrive':
      return 'Guilty Gear Strive'
    case 'meltyBloodTypeLumina':
      return 'メルブラルミナ'
    case 'othellonia':
      return '逆転オセロニア'
    case 'pokkenTournamentDx':
      return 'ポッ拳DX'
    case 'sekainoAsobi51':
      return '世界のアソビ大全51'
    case 'marioKart8Dx':
      return 'マリオカート8DX'
    case 'diablo3':
      return 'ディアブロⅢ'
    case 'diablo4':
      return 'ディアブロⅣ'
    case 'bloodHunt':
      return 'Bloodhunt'
    case 'sky':
      return 'Sky 星を紡ぐ子どもたち'
    case 'brawlStars':
      return 'ブロスタ'
    case 'tekken7':
      return '鉄拳7'
    case 'knivesOut':
      return '荒野行動'
    case 'pubg':
      return 'PUBG'
    case 'pubgMobile':
      return 'PUBGモバイル'
    case 'pubgNewState':
      return 'NEW STATE MOBILE'
    case 'fallguys':
      return 'Fall Guys'
    case 'r6s':
      return 'レインボーシックスシージ'
    case 'rainbowSixExtraction':
      return 'レインボーシックス:エクストラクション'
    case 'destiny2':
      return 'Destiny 2'
    case 'proSeka':
      return 'プロセカ'
    case 'mahjongSoul':
      return '雀魂'
    case 'mahjongKings':
      return '雀皇麻雀'
    case 'pazudora':
      return 'パズドラ'
    case 'shadowverse':
      return 'シャドウバース'
    case 'shironekoTennis':
      return '白猫テニス'
    case 'monsterStrike':
      return 'モンスト'
    case 'rocketLeague':
      return 'Rocket League'
    case 'momotetsuTeiban':
      return '桃太郎電鉄定番'
    case 'bf5':
      return 'バトルフィールド5'
    case 'bf2042':
      return 'バトルフィールド2042'
    case 'edf5':
      return '地球防衛軍5'
    case 'edf6':
      return '地球防衛軍6'
    case 'compass':
      return 'コンパス【戦闘摂理解析システム】'
    case 'vrChat':
      return 'VRChat'
    case 'roblox':
      return 'ロブロックス'
    case 'needForSpeed':
      return 'ニード・フォー・スピード'
    case 'granTurismoSport':
      return 'グランツーリスモスポーツ'
    case 'granTurismo7':
      return 'グランツーリスモ7'
    case 'puyopuyoTetris2':
      return 'ぷよぷよテトリス2'
    case 'lifeAfter':
      return 'ライフアフター'
    case 'overcooked':
      return 'オーバークック'
    case 'overcooked2':
      return 'オーバークック2'
    case 'mobileLegends':
      return 'モバイルレジェンド'
    case 'warframe':
      return 'Warframe'
    case 'bangDream':
      return 'バンドリ'
    case 'duelMastersPlays':
      return 'デュエプレ'
    case 'worldOfWarshipsLegends':
      return 'World of Warships: Legends'
    case 'worldOfWarshipsTanks':
      return 'World of Tanks'
    case 'factorio':
      return 'Factorio'
    case 'darkAndDarker':
      return 'Dark and Darker'
    case 'dragonQuest10':
      return 'ドラクエⅩ'
    case 'houkai3rd':
      return '崩壊3rd'
    case 'houkaiStarRail':
      return '崩壊スターレイル'
    case 'unrailed':
      return 'Unrailed!'
    case 'theDivision2Pc':
      return 'ディビジョン2 (PC)'
    case 'theDivision2PlayStation':
      return 'ディビジョン2 (PS)'
    case 'nanatsunotaizaiGrandCross':
      return '七つの大罪 ～光と闇の交戦（グランドクロス）～'
    case 'westHunt':
      return 'West Hunt'
    case 'towerOfFantasy':
      return '幻塔'
    case 'titanFall2':
      return 'タイタンフォール2'
    case 'woLongFallenDynasty':
      return 'ウォーロン:フォールン ダイナスティ'
    case 'codeVein':
      return 'コードヴェイン'
    case 'fifa2023':
      return 'FIFA23'
    case 'eFootball2023':
      return 'eFootball 2023'
    case 'gears5':
      return 'Gears 5'
    case 'haloInfinite':
      return 'Halo Infinite'
    case 'farlight84':
      return 'Farlight84'
    case 'omegaStrikers':
      return 'オメガストライカーズ'
    case 'xdefiant':
      return 'XDefiant'
    case 'earthRevival':
      return 'アースリバイバル'
    case 'ashTale':
      return 'Ash Tale-風の大陸-'
    case 'ultimateChickenHorse':
      return 'Ultimate Chicken Horse'
    case 'gangBeasts':
      return 'Gang Beasts'
    case 'noMansSky':
      return "No Man's Sky"
    case 'sonsOfTheForest':
      return 'Sons of the Forest'
    case 'sevenDaysToDie':
      return '7 Days to Die'
    case 'theOutlastTrials':
      return 'The Outlast Trials'
    case 'jumpForce':
      return 'ジャンプフォース'
    case 'godField':
      return 'ゴッドフィールド'
    case 'secretNeighbor':
      return 'Secret Neighbor'
    case 'marvelsAvengers':
      return "Marvel's Avengers"
    case 'meetYourMaker':
      return 'Meet Your Maker'
    case 'arcaea':
      return 'Arcaea'
    case 'blueProtocol':
      return 'ブループロトコル'
    case 'onePieceBountyRush':
      return 'ワンピース バウンティラッシュ'
    case 'onePieceThousandStorm':
      return 'ワンピース サウザンドストーム'
    case 'nioh2':
      return '仁王2'
    case 'residentEvil5':
      return 'バイオハザード5'
    case 'ghostWatchers':
      return 'Ghost Watchers'
    case 'itTakesTwo':
      return 'It Takes Two'
    case 'pacify':
      return 'Pacify'
    case 'raft':
      return 'Raft'
    case 'aceCombat7':
      return 'エースコンバット7'
    case 'garticPhone':
      return 'Gartic Phone'
    case 'dontStarveTogether':
      return "Don't Starve Together"
    case 'battleBitRemastered':
      return 'BattleBit Remastered'
    case 'blackDesertPc':
      return '黒い砂漠 (PC)'
    case 'blackDesertConsole':
      return '黒い砂漠 (Console)'
    case 'blackDesertMobile':
      return '黒い砂漠 (Mobile)'
    case 'logresJapaneseRpg':
      return 'ログレス of ブレイブ'
    case 'theHunterCallOfTheWild':
      return 'theHunter: Call of the Wild'
    case 'exoprimal':
      return 'EXO PRIMAL'
    case 'redDeadRedemption2':
      return 'Red Dead Redemption 2'
    case 'rogueCompany':
      return 'Rogue Company'
    case 'theLegendOfZeldaTearsOfTheKingdom':
      return 'ゼルダの伝説 時の涙'
    case 'pikmin4':
      return 'ピクミン4'
    case 'craftopia':
      return 'Craftopia'
    case 'daemonXMachina':
      return 'デモンエクスマキナ'
    case 'narakaBladepoint':
      return 'NARAKA: BLADEPOINT'
    case 'godEater3':
      return 'ゴッドイーター3'
    case 'remnant2':
      return 'Remnant 2'
    case 'plateUp':
      return 'Plate Up'
    case 'clashRoyale':
      return 'クラロワ'
    case 'clashOfClans':
      return 'クラクラ'
    case 'coreKeeper':
      return 'Core Keeper'
    case 'paladins':
      return 'Paladins'
    case 'dyingLight':
      return 'ダイイングライト'
    case 'hogwartsLegacy':
      return 'Hogwarts Legacy'
    case 'projectF':
      return 'Project F'
    case 'onePieceBountyRush':
      return 'ワンピース バウンティラッシュ'
    case 'farCry6':
      return 'Far Cry 6'
    case 'arenaBreakout':
      return 'Arena Breakout'
    case 'ghostReconWildlands':
      return 'ゴーストリコン ワイルドランズ'
    case 't3Arena':
      return 'T3アリーナ'
    case 'starSiegeDeadZone':
      return 'Star Siege: Dead Zone'
    case 'worldDaiStar':
      return 'World 大Star'
    case 'superMarioMaker2':
      return 'スーパーマリオメーカー 2'
    case 'smashDunk':
      return 'スマッシュダンク'
    case 'castleAndDragon':
      return '城ドラ'
    case 'odinValhallaRising':
      return 'オーディン: ヴァルハラライジング'
    case 'nyankoDaisensou':
      return 'にゃんこ大戦争'
    case 'professionalBaseballSpiritsA':
      return 'プロスピA'
    case 'saoIntegralFactor':
      return 'SAO:IF'
    case 'lostLight':
      return 'Lost Light'
    case 'albionOnline':
      return 'Albion Online'
    case 'fallout76':
      return 'Fallout 76'
    case 'dissidiaFinalFantasyNt':
      return 'ディシディアファイナルファンタジー NT'
    case 'armoredCore6':
      return 'アーマード・コア6'
    case 'satisfactory':
      return 'Satisfactory'
    case 'goddessOfVictoryNikke':
      return '勝利の女神ニッケ'
    case 'sengokuDynasty':
      return '戦国DYNASTY'
    case 'predeterHuntingGrounds':
      return 'Predator: Hunting Grounds'
    case 'theTexasChainSawMassacre':
      return 'The Texas Chainsaw Massacre'
    case 'wayfinder':
      return 'Wayfinder'
    case 'terraria':
      return 'テラリア'
    case 'valheim':
      return 'Valheim'
    case 'stardewValley':
      return 'Stardew Valley'
    case 'payDay2':
      return 'PAYDAY 2'
    case 'payDay3':
      return 'PAYDAY 3'
    case 'synced':
      return 'SYNCED'
    case 'darkSouls3':
      return 'ダークソウル3'
    case 'umaMusumePrettyDerby':
      return 'ウマ娘 プリティーダービー'
    case 'yokaiPunipuni':
      return '妖怪ウォッチぷにぷに'
    case 'superBombermanR2':
      return 'スーパーボンバーマン R 2'
    case 'joJosBizarreAdventureAllStarBattleR':
      return 'ジョジョの奇妙な冒険 オールスターバトルR'
    case 'partyAnimals':
      return 'Party Animals'
    case 'warThunder':
      return 'War Thunder'
    case 'blueArchive':
      return 'ブルーアーカイブ'
    case 'fridayThe13thTheGame':
      return 'Friday the 13th: The Game'
    case 'powerfulProBaseballGloryNineCrossroads':
      return '実況パワフルプロ野球 栄光のナインクロスローズ'
    case 'fateGrandOrder':
      return 'Fate/Grand Order'
    case 'eaSportsFc24':
      return 'EA SPORTS FC 24'
    case 'farmingSimulator22':
      return 'Farming Simulator 22'
    default:
      return gameTitle
  }
}
